<template>
  <div class="interpreter">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="interpreter-back"
      @click="back()"
    />
    <div class="interpreter-bgw">
      <div class="interpreter-bgw-user">
        <img
          src="https://cdn.bubbleplan.cn/static/activity/Frame9646.png"
          alt=""
          class="interpreter-bgw-user-title"
        />
        <div
          class="interpreter-bgw-user-earnings flex"
          @click="viewEarningsButton()"
        >
          <div>查看收益</div>
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/activity/Group10793.png"
            alt=""
            class="interpreter-bgw-user-earnings-img"
          />
        </div>
        <div class="interpreter-bgw-user-list flex">
          <div
            v-for="(item, index) in 2"
            :key="index"
            class="interpreter-bgw-user-list-li flex"
          >
            <img
              :src="
                index == 0
                  ? 'https://cdn.bubbleplan.cn/m_bubble/activity/Group10747.png'
                  : 'https://cdn.bubbleplan.cn/m_bubble/activity/Group107471.png'
              "
              mode=""
              class="interpreter-bgw-user-list-li-img"
            />
            <div>
              <div class="interpreter-bgw-user-list-li-text">
                每日释放{{ index == 0 ? "芯片" : "晶体" }}积分
              </div>
              <div class="interpreter-bgw-user-list-li-num">
                {{ index == 0 ? dailyChipScore : daliDac }}
              </div>
            </div>
          </div>
          <div
            class="interpreter-bgw-user-list-button"
            @click="transitionButton(4)"
          >
            转换
          </div>
        </div>
      </div>
      <div class="interpreter-bgw-ul flex">
        <div
          v-for="(item, index) in activityData"
          :key="index"
          class="interpreter-bgw-ul-li"
          @click="transitionButton(index)"
        >
          <div
            class="interpreter-bgw-ul-li-bgc"
            :class="'interpreter-bgw-ul-li-bgc' + index"
          ></div>
          <img
            v-if="item.consume != 0"
            :src="
              'https://cdn.bubbleplan.cn/m_bubble/activity/20241022-170018' +
              index +
              '.gif'
            "
            mode=""
            class="interpreter-bgw-ul-li-img"
          />
        </div>
      </div>

      <el-dialog
        title=""
        :visible.sync="show"
        width="30%"
        :before-close="handleClose"
        :show-close="false"
      >
        <div class="addition">
          <img
            src="https://cdn.bubbleplan.cn/m_bubble/marketplace/X2x.png"
            mode=""
            class="addition-img"
            @click="handleClose()"
          />
          <div class="flex" style="justify-content: space-between">
            <div>
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/home/1231231231.png"
                mode=""
                class="addition-bacw"
              />
              <div class="addition-num" v-if="activityData[type]">
                ×{{ activityData[type].putIn ** 1 * (value / 10) }}
              </div>
            </div>
            <div class="addition-ccc">
              <img
                src="https://cdn.bubbleplan.cn/m_bubble/home/43432423.png"
                mode=""
                class="addition-bacw"
              />
              <div class="addition-num" v-if="activityData[type]">
                ×{{ activityData[type].putIn * 1 * 3 * (value / 10) }}
              </div>
            </div>
          </div>
          <div class="addition-progressbar flex">
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/add.png"
              mode=""
              class="addition-progressbar-img right19"
              style=""
              @click="subtract"
            />
            <el-slider
              v-model="value"
              :show-tooltip="false"
              height="7"
              :step="10"
              :min="10"
              :max="100"
            ></el-slider>
            <img
              src="https://cdn.bubbleplan.cn/m_bubble/home/reduce.png"
              mode=""
              class="addition-progressbar-img left14"
              style=""
              @click="addnum"
            />
          </div>
          <div class="addition-button" @click="putinto">投入</div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="regulationShow"
        width="60%"
        :show-close="false"
      >
        <div class="regulationShowsBgc">
          <div class="regulationShowsBgc-title">
            <img
              src="http://cdn-bubbleplan.oss-cn-hangzhou.aliyuncs.com/static/drawalotteryorraffle/X.png"
              alt=""
              class="regulationShowsBgc-x"
              @click="regulationShow = false"
            />质押规则
          </div>
          <div class="regulationShowsBgc-text">
            <div>
              用户可以质押数量为100或2000的芯片，单次质押周期最小值为一个月。
            </div>
            <div class="regulationShowsBgc-text-top">
              在质押时间到期后将获得质押数量的芯片，以及一定数量的质押奖励晶体。
            </div>
            <div class="regulationShowsBgc-text-top">
              单账号的实验室转化机有限。小型转化机10个，大型转化机10个。
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      regulationShow: false,
      show: false,
      value: 10,
      activityData: [],
      type: null,
      dailyChipScore: 0,
      daliDac: 0,
    };
  },
  mounted() {
    this.dailyChipScore = 0;
    this.daliDac = 0;
    this.listAllPledge();
    this.everyDayIncome();
    this.userNum();
  },
  methods: {
    transitionButton(type) {
      this.$router.push(`/activity/interpreter/transition?type=${type}`);
    },

    viewEarningsButton() {
      this.$router.push("/activity/interpreter/viewEarnings");
    },
    // 查看每日释放
    async everyDayIncome() {
      const { data, code } = await this.$ajax.everyDayIncome();
      data.forEach((item, index) => {
        this.dailyChipScore += item[0].outNum * 1;

        this.daliDac += item[1].outNum * 1;
      });
      this.dailyChipScore = Math.round(this.dailyChipScore * 100) / 100;
      this.daliDac = Math.round(this.daliDac * 100) / 100;
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
      }
    },
    back() {
      history.back();
    },
    interpreter() {
      this.regulationShow = true;
    },
    //投入
    async putinto() {
      const params = {
        pledgeId: this.activityData[this.type].id,
        num: this.value / 10,
      };
      const { data, code } = await this.$ajax.doPledge(params);
      this.handleClose();
      this.listAllPledge();
      this.userNum();
    },
    subtract() {
      if (this.value == 10) {
        this.value = 10;
      } else {
        this.value -= 10;
      }
    },
    addnum() {
      if (this.value == 100) {
        this.value = 100;
      } else {
        this.value += 10;
      }
    },
    doPledge(type) {
      this.type = type;
      this.show = true;
    },
    async listAllPledge() {
      const { data, code } = await this.$ajax.listAllPledge();
      this.activityData = data;
    },
    handleClose() {
      this.show = false;
      this.value = 10;
    },
  },
};
</script>

<style lang="scss" scoped>
.interpreter {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/activity/bgc.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 40px;
  overflow: auto;
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-bgw {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    &-user {
      width: 1116px;
      height: 275px;
      background: url("https://cdn.bubbleplan.cn/static/activity/Group10825.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 36px 72.78px 0 80px;
      position: relative;
      &-title {
        width: 302px;
        height: 45px;
      }
      &-earnings:hover {
        cursor: pointer;
      }
      &-earnings {
        position: absolute;
        top: 59px;
        right: 9.95px;
        width: 145px;
        height: 51px;
        background: url("https://cdn.bubbleplan.cn/static/activity/Group10824.png")
          no-repeat;
        background-size: 100% 100%;
        color: #ff9029;
        font-size: 22px;
        align-items: center;
        justify-content: center;
        &-img {
          width: 19px;
          height: 19px;
        }
      }
      &-list {
        margin-top: 16.27px;
        align-items: center;
        justify-content: space-between;
        &-li {
          width: 350px;
          height: 107px;
          border-radius: 13.5px;
          background: #adb3c9;
          align-items: center;
          padding: 0 23px;
          &-img {
            width: 75px;
            height: 75px;
            margin-right: 19.37px;
          }
          &-text {
            color: #ffffff;
            font-family: "TsangerYuMo1";
            font-size: 22px;
            text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
              2px 2px 0 #000;
          }
          &-num {
            margin-top: 7px;
            height: 37px;
            line-height: 37px;
            padding: 0 7px;
            border-radius: 7.25px;
            background: #f86eff;
            color: #ffffff;
            font-family: "TsangerYuMo1";
            font-size: 26px;
            display: inline-block;
          }
        }
        &-button:hover {
          cursor: pointer;
        }
        &-button {
          width: 223px;
          height: 83px;
          background: url("https://cdn.bubbleplan.cn/static/activity/A08_pop_btn_029.png")
            no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: 83px;
          color: #ffffff;
          text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
            2px 2px 0 #000;
          font-family: "TsangerYuMo1";
          font-size: 29px;
          margin-top: 10px;
        }
      }
    }
    &-ul {
      margin-top: 20px;
      &-li:hover {
        cursor: pointer;
      }
      &-li {
        width: 361px;
        height: 220px;
        background: url("https://cdn.bubbleplan.cn/static/activity/Group10864.png")
          no-repeat;
        background-size: 100% 100%;
        margin: auto;
        position: relative;
        margin-right: 10px;
        &-bgc0 {
          width: 372px;
          height: 208px;
          position: absolute;
          top: 0px;
          left: -6px;
          z-index: 9;
          background: url("https://cdn.bubbleplan.cn/static/activity/Group108190.png")
            no-repeat;
          background-size: 100% 100%;
        }
        &-bgc1 {
          width: 372px;
          height: 208px;
          position: absolute;
          top: 0px;
          left: -6px;
          z-index: 9;
          background: url("https://cdn.bubbleplan.cn/static/activity/Group108191.png")
            no-repeat;
          background-size: 100% 100%;
        }
        &-bgc2 {
          width: 372px;
          height: 208px;
          position: absolute;
          top: 0px;
          left: -6px;
          z-index: 9;
          background: url("https://cdn.bubbleplan.cn/static/activity/Group108192.png")
            no-repeat;
          background-size: 100% 100%;
        }
        &-img {
          width: 356px;
          height: 155px;
          margin: auto;
          margin-top: 35px;
        }
      }
    }

    :deep(.el-dialog) {
      background: transparent;
    }
    .addition {
      width: 689px;
      height: 470px;
      background: url("https://cdn.bubbleplan.cn/static/activity/Group10580.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 0 60px;
      padding-top: 69px;
      text-align: center;
      align-items: center;
      .right19 {
        margin-right: 19px;
      }
      .left14 {
        margin-left: 14px;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 67px;
        height: 66px;
        position: absolute;
        right: -22px;
        top: -20px;
      }

      &-bacw {
        margin: auto;
        width: 158px;
        height: 158px;
      }

      .addition-ccc {
        margin-right: 7px;
      }

      &-num {
        color: #ffffff;
        font-family: "TsangerYuMo";
        font-size: 32px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000;
        /* 模拟描边效果 */
        margin-top: -40px;
      }

      &-progressbar {
        align-items: center;
        &-img:hover {
          cursor: pointer;
        }
        &-img {
          width: 43px;
          height: 47px;
        }
        :deep(.el-slider) {
          width: 100%;
        }
        :deep(.el-slider__bar) {
          background-color: #fff;
        }
        :deep(.el-slider__button) {
          width: 33px !important;
          height: 49px !important;
          background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector740.png")
            no-repeat;
          background-size: 100% 100%;
          background-color: transparent !important;
          margin-top: -6px !important;
          border: none !important;
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        width: 194px;
        height: 80px;
        background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector594.png")
          no-repeat;
        background-size: 100% 100%;
        margin: auto;
        margin-top: 10px;
        color: #ffffff;
        text-align: center;
        line-height: 80px;
        font-family: "TsangerYuMo";
        font-size: 30px;
        font-weight: bold;
        text-shadow: -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000,
          2px 2px 0 #000000;
        /* 模拟描边效果 */
      }
    }
    :deep(.el-dialog) {
      margin-top: 10vh !important;
    }
    .regulationShowsBgc {
      width: 1186px;
      height: 745px;
      background: url("https://cdn.bubbleplan.cn/static/drawalotteryorraffle/Group9870.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding-top: 94px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 78px 124px 103.34px 168px;
      &-x {
        cursor: pointer;
      }
      &-x {
        position: absolute;
        top: 63.96px;
        right: 36.76px;
        width: 80.88px;
        height: 79.56px;
      }
      &-title {
        width: 100%;
        color: #574352;
        font-size: 48px;
        font-weight: 900;
        text-align: center;
      }
      &-text::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      &-text {
        flex: 1;
        overflow: auto;
        margin-top: 40px;
        color: #574352;
        font-size: 24px;
        line-height: 40.8px;
        // background: #d6c4ba;
        padding: 45px 50px;
        &-top {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>